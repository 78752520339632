import React, { useState } from "react"
import Footer from "./footer"
import SEO from "./seo"
import Toolbar from "./toolbar"
import SideDrawer from "../SideDrawer/SideDrawer"
import BackDrop from "../BackDrop/backDrop"

const Layout = ({ children }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const drawerToggleClickHandler = () => {
    let Prev = sideDrawerOpen
    setSideDrawerOpen(!Prev)
  }
  const backDropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  let backDrop
  if (sideDrawerOpen) {
    backDrop = <BackDrop click={backDropClickHandler} />
  }
  return (
    <div style={{ height: "100%" }}>
      <SEO />
      <Toolbar drawerToggleClickHandler={drawerToggleClickHandler} />
      <SideDrawer show={sideDrawerOpen} />
      {backDrop}

      {children}
      <Footer />
    </div>
  )
}

export default Layout
