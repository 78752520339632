import React from "react"
import Styles from "./toolbar.module.css"
import Logo from "../../asset/Icon.png"
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton"
import { Link } from "gatsby"

const Toolbar = props => {
  return (
    <header className={Styles.toolbar}>
      <nav className={Styles.toolbarNavigation}>
        <div className={Styles.toolbarTobbleButton}>
          <DrawerToggleButton click={props.drawerToggleClickHandler} />
        </div>

        <div className={Styles.toolbarNavigationItems}>
          <Link className={Styles.list} to="/">
            <p>Accueil</p>
          </Link>
          <Link className={Styles.list} to="/programme">
            <p>Programmation</p>
          </Link>

          <Link className={Styles.list} to="/nathalie">
            A propos
          </Link>
        </div>
        <div className={Styles.spacer}></div>
        <div className={Styles.toolbarLogo}>
          <img src={Logo} width={54} height={54} />
        </div>
      </nav>
    </header>
  )
}

export default Toolbar
