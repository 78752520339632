import React from "react"
import Styles from "./DrawerToggleButton.module.css"

const DrawerToggleButton = props => {
  return (
    <button className={Styles.toggleButton} onClick={props.click}>
      <div className={Styles.toggleButtonLine}></div>
      <div className={Styles.toggleButtonLine}></div>
      <div className={Styles.toggleButtonLine}></div>
    </button>
  )
}

export default DrawerToggleButton
