import React from "react"
import Styles from "./footer.module.css"
import { graphql } from "gatsby"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Lesharmoniques from "../../asset/doc/Les-Harmoniques.pdf"
import Guide from "../../asset/doc/Guide.pdf"
import Memoire from "../../asset/doc/Memoire.pdf"
import Album from "../../asset/doc/Album.pdf"
import GMF from "../../asset/doc/GMF.pdf"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          qrCode: file(relativePath: { eq: "qrcode.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          document: allFile(filter: { relativeDirectory: { eq: "doc" } }) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `}
      render={data => (
        <footer>
          <div className={Styles.footercontainer}>
            <div className={Styles.image}>
              <Img
                alt=""
                fluid={data.qrCode.childImageSharp.fluid}
                style={{
                  width: "70%",
                  margin: "auto",
                }}
              />
            </div>
            <div className={Styles.footercolumn}>
              <span className={Styles.footertitle}>Télécharger</span>
              <a
                href={GMF}
                target="_blank"
                rel="noopener noreferrer"
                className={Styles.important}
              >
                Attestation d'assurance télétravail
              </a>
              <a
                href={Lesharmoniques}
                target="_blank"
                rel="noopener noreferrer"
              >
                Audition du 16 juin 2018
              </a>

              <a href={Guide} target="_blank" rel="noopener noreferrer">
                Guide
              </a>
              <a href={Album} target="_blank" rel="noopener noreferrer">
                Article de Presse D.N.A. 03/11/17
              </a>
              <a href={Memoire} target="_blank" rel="noopener noreferrer">
                Mémoire
              </a>
              <a
                href="https://www.youtube.com/watch?v=khMdP4vZVQg&feature=youtu.be"
                target="_blank"
                rel="noopener noreferrer"
              >
                Video Youtube
              </a>
            </div>

            <div className={Styles.footercolumn}>
              <span className={Styles.footertitle}>Liens utiles</span>

              <a
                href="https://www.facebook.com/Les-Harmoniques-Classe-de-Natalie-Guillermic-1451787178449691/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Les Harmoniques sur facebook
              </a>

              <a
                href="https://lemoine-education.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Lemoine Education
              </a>

              <a
                href="https://www.pianoshanlet.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pianos Hanlet
              </a>

              <a
                href="https://www.di-arezzo.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Di-arezzo
              </a>
            </div>

            <div className={Styles.footercolumn}>
              <span className={Styles.footertitle}>Contact</span>

              <span>Les Harmoniques</span>
              <span>10 rue Crespin du Gast</span>
              <span> Paris</span>
              <span>06 89 61 72 52</span>
              <span>lesharmoniques27@orange.fr</span>
            </div>

            <div className={Styles.footercolumn}>
              <span className={Styles.footertitle}>Moyens de paiement</span>

              <span>Espèces</span>
              <span>Chèques bancaires</span>
              <span>Terminal SUMUP</span>
              <span>Anglais</span>
              <span>Italien</span>
            </div>
          </div>
          <div className={Styles.copyright}>
            ©&nbsp;&nbsp;Copyright 2019 -
            2022&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;Tous droits réservés
          </div>
        </footer>
      )}
    />
  )
}
