import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

const SEO = () => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { url, title, description, image },
      },
    }) => (
      <Helmet title={title}>
        <html lang="en" />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary" />
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
    )}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        url
        image
      }
    }
  }
`
