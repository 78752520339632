import React from "react"
import "./SideDrawer.css"
import Logo from "../../asset/Icon.png"
import { Link } from "gatsby"
import { FaPhone, FaMailBulk } from "react-icons/fa"

const SideDrawer = props => {
  let drawerClasses = "side-drawer"
  if (props.show === true) {
    drawerClasses = "side-drawer open"
  }

  return (
    <nav className={drawerClasses}>
      <div>
        <div className="image">
          <img src={Logo} width={48} height={48} alt="" />
        </div>

        <h2> Les harmoniques</h2>
        <div className="title"> Leçon de Piano à domicile</div>
        <div className="menu">
          <Link to="/">
            <div className="menuList">Accueil</div>
          </Link>

          <Link to="/programme">
            <div className="menuList">Programme</div>
          </Link>

          <Link to="/nathalie">
            <div className="menuList">A propos</div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default SideDrawer
