import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styles from "../Layout/header.module.css"

const dataList = [
  {
    page: "index",
    title: "Leçons de piano à domicile Paris XI",
    subtitle: "Les Harmoniques",
    image: "header.jpg",
  },
  {
    page: "page2",
    title: "Les Harmoniques",
    subtitle: "A propos de Nathalie",
    image: "header2.jpg",
  },
  {
    page: "page3",
    title: "Récital de piano",
    subtitle: "Suggestion de programme",
    image: "header3.jpg",
  },
]

const Header = ({ header }) => {
  const headerData = dataList.find(element => {
    return element.page === header
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          imageList: allFile(filter: { relativeDirectory: { eq: "header" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 1500, maxHeight: 314) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className={Styles.headerWrapper}>
            <div className={Styles.title}>{headerData["title"]}</div>
            <div className={Styles.subtitle}>{headerData["subtitle"]}</div>
            <Img
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
              }}
              fluid={
                data.imageList.edges.filter(element =>
                  element.node.childImageSharp.fluid.src.includes(
                    headerData["image"]
                  )
                )[0].node.childImageSharp.fluid
              }
            />
          </div>
        )
      }}
    />
  )
}

export default Header
